






































































































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import StatisticsRepository from '@/api/repositories/StatisticsRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Statistics from '@/models/Statistics';
import StatisticOverview from '@/models/StatisticOverview';
import Ride from '@/models/Ride';
import RideRepository from '@/api/repositories/RideRepository';

 interface StatisticsEntry {
     title: string,
     value: number
}

const statisticsRepository: StatisticsRepository = RepositoryFactory.get('statistics');
const rideRepository: RideRepository = RepositoryFactory.get('ride');

@Component({
    components: {
        RideTableComponent: () => import(
            /* webpackChunkName: "RideTableComponent" */
            '@/components/RideTable.component.vue')
    }
})
export default class StatisticsView extends Vue {
    public startMenu: boolean = false;
    public endMenu: boolean = false;
    public startDate: string | null = null;
    public endDate: string | null = null;

    private isLoadingStatisticOverview: boolean = true;
    private isLoadingStatistics: boolean = true;
    private isLoadingCanceledRides: boolean = true;
    private isExpanding: boolean = false;

    private statisticOverview!: StatisticOverview;
    private statistics!: Statistics;
    private canceledRides: Ride[] = [];

    private hasOverviewError: boolean = false;
    private hasStatisticError: boolean = false;

    async created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');

        await Promise.all([
            await this.loadOverview(),
            await this.generateClicked()
        ]);
    }

    private async loadOverview() {
        try {
            this.isLoadingStatisticOverview = true;
            const response = await statisticsRepository.getStatistics();
            this.statisticOverview = StatisticOverview.parseFromObject(response.data);
            this.hasOverviewError = false;
        } catch (e) {
            this.hasOverviewError = true;
        } finally {
            this.isLoadingStatisticOverview = false;
        }
    }

    private async generateClicked() {
        if (!this.startDate || !this.endDate) return;

        try {
            this.isLoadingStatistics = true;
            const response = await statisticsRepository.getSales(this.startDate, this.endDate);
            this.statistics = Statistics.parseFromObject(response.data);
            await this.loadCanceledRides();
            this.hasStatisticError = false;
        } catch (e) {
            this.hasStatisticError = true;
        } finally {
            this.isLoadingStatistics = false;
        }
    }

    private async loadCanceledRides() {
        try {
            this.isLoadingCanceledRides = true;
            const response = await rideRepository.canceledRides(this.startDate, this.endDate);
            this.canceledRides = Ride.parseFromArray(response.data) as Ride[];
        } finally {
            this.isLoadingCanceledRides = false;
        }
    }

    private get statisticsEntries(): StatisticsEntry[] {
        if (!this.statisticOverview) return [];
        return [
            { title: this.$t('STATISTICS.ACTIVE_COMPANIES').toString(), value: this.statisticOverview.activeCompanies },
            { title: this.$t('STATISTICS.ACTIVE_DRIVERS').toString(), value: this.statisticOverview.activeDrivers },
            { title: this.$t('STATISTICS.ALL_DRIVERS').toString(), value: this.statisticOverview.allDrivers },
            { title: this.$t('STATISTICS.FINISHED_RIDES').toString(), value: this.statisticOverview.finishedRides },
            { title: this.$t('STATISTICS.CANCELED_RIDES').toString(), value: this.statisticOverview.canceledRides }
        ];
    }
}
