import Parseable from '@/misc/Parseable';

export default class StatisticOverview extends Parseable {
    public activeCompanies!: number;
    public activeDrivers!: number;
    public allDrivers!: number;
    public canceledRides!: number;
    public finishedRides!: number;

    public static parseFromObject(object: Partial<StatisticOverview>): StatisticOverview {
        const statisticOverview = new StatisticOverview();
        Object.assign(statisticOverview, object);
        return statisticOverview;
    }

    public parseToObject(): Partial<StatisticOverview> {
        const tmp = { ...this };
        return tmp;
    }
}
