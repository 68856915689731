import Parseable from '@/misc/Parseable';

export default class Statistics extends Parseable {
    public appSales!: number;
    public shareSales!: number;
    public creditSales!: number;

    public static parseFromObject(object: Partial<Statistics>): Statistics {
        const statistics = new Statistics();
        Object.assign(statistics, object);
        return statistics;
    }

    public parseToObject(): Partial<Statistics> {
        const tmp = { ...this };
        return tmp;
    }
}
